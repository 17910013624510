import React from "react"
import BandsBackdrop from "./bands-backdrop"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import concerto from "../images/logos/concerto.svg"
import genesys from "../images/logos/genesys.svg"
import JLL from "../images/logos/jll.svg"
import TTEC from "../images/logos/ttec.svg"
import Wazoku from "../images/logos/wazoku.svg"
import IBM from "../images/logos/IBM_Partner_Plus_platinum_partner_mark_pos_platinum_RGB.svg"
import Bytes from "../images/logos/bytes.png"
import Microsoft from "../images/logos/microsoft.png"
import HIDGlobal from "../images/logos/hidglobal.svg"
import AWS from "../images/logos/aws.svg"

const AwardSponsors = ({children, gridColor}) => (
    <div>
        <h3 className="text-center my-5" style={{
            color: `#fff`,
            fontFamily: `Avenir`,
            fontWeight: `900`,
            fontSize: `3.5rem`,
        }}>Sponsors</h3>
        <p className="text-center" style={{
            color: `#fff`,
        }} hidden>Public Finance Awards sponsorship provides the unmissable opportunity to create exposure for your brand, as well as the opportunity to advertise your products and services to 14,000 CIPFA members and the wider public finance community.</p>

        <div className="row justify-content-center text-center">
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.concerto.co.uk/" target="_blank"><img src={concerto} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Improving Spaces</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <div className="row">
                    <div className="col"><OutboundLink href="https://www.genesys.com/en-gb" target="_blank"><img src={genesys} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink></div>
                    <div className="col"><OutboundLink href="https://ttecdigital.com/" target="_blank"><img src={TTEC} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink></div>
                  </div>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Customer Experience</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.ibm.com/uk-en" target="_blank"><img src={IBM} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Automation, AI and Machine Learning</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.jll.co.uk" target="_blank"><img src={JLL} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Future Focused and Sustainable Property</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4 d-flex justify-content-center align-items-center" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.wazoku.com/" target="_blank"><img src={Wazoku} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Innovation as a Service</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.hidglobal.com/" target="_blank"><img src={ HIDGlobal } className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">User Experience</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://aws.amazon.com/" target="_blank"><img src={ AWS } className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Small-scale, Big Impact</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <div className="row">
                    <div className="col"><OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Bytes} className="img-fluid p-2" /></OutboundLink></div>
                    <div className="col"><img src={Microsoft} className="img-fluid p-2" style={{ maxHeight: `4rem`}} /></div>
                  </div>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Special Recognition</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <div className="row">
                    <div className="col"><OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Bytes} className="img-fluid p-2" /></OutboundLink></div>
                    <div className="col"><img src={Microsoft} className="img-fluid p-2" style={{ maxHeight: `4rem`}} /></div>
                  </div>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Beyond Smarter Working</p>
              </div>
            </BandsBackdrop>
          </div>
      </div>
    </div>
)

AwardSponsors.defaultProps = {
    gridColor: `#d23464`
  }

export default AwardSponsors
